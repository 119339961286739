.teaser.cmp-teaser-bg-grey {
  background: #f8f8fd;
}

.teaser.cmp-teaser-bg-blue {
  background: #eef7f7;
}

.teaser.cmp-teaser-bg-grey, .teaser.cmp-teaser-bg-blue {
  padding: 1rem;
}

main .cmp-container .aem-Grid .teaser.cmp-teaser-bg-grey, main .cmp-container .aem-Grid .teaser.cmp-teaser-bg-blue {
  margin: 3.125rem 0;
}

.teaser.cmp-teaser-center {
  text-align: center;
}

@media screen and (width <= 1024px) {
  .teaser.cmp-teaser-center .cmp-teaser-bloc {
    margin: auto;
  }
}

.teaser.cmp-teaser-center .cmp-teaser-image, .teaser.cmp-teaser-center .cmp-teaser-arrow-link {
  margin: auto;
}

.teaser.cmp-teaser-center .cmp-teaser ul {
  text-align: left;
}

.teaser.cmp-teaser-center .cmp-teaser-title {
  padding-left: 0;
}

.teaser.cmp-teaser-frame .cmp-teaser .cmp-teaser-content .cmp-teaser-bloc, .teaser.cmp-teaser-frame .cmp-teaser .cmp-teaser-content .cmp-teaser-bloc.image-with-button {
  border: 1px solid #dadadd;
  border-radius: .25rem;
  margin: .5rem;
  padding: 2rem 1rem;
}

@media screen and (width <= 1024px) {
  .teaser.cmp-teaser-frame .cmp-teaser .cmp-teaser-content .cmp-teaser-bloc, .teaser.cmp-teaser-frame .cmp-teaser .cmp-teaser-content .cmp-teaser-bloc.image-with-button {
    margin: .5rem auto;
  }
}

.teaser.cmp-teaser-separator .cmp-teaser-bloc:not(:not(:has( ~ .cmp-teaser-bloc))) {
  border-right: 1px solid #8b8a93;
}

@media screen and (width <= 1024px) {
  .teaser.cmp-teaser-separator .cmp-teaser-bloc:not(:not(:has( ~ .cmp-teaser-bloc))) {
    border: none;
  }
}

.teaser.cmp-teaser-separator .cmp-teaser-bloc:not(:not(:has( ~ .cmp-teaser-bloc))).image-right, .teaser.cmp-teaser-separator .cmp-teaser-bloc:not(:not(:has( ~ .cmp-teaser-bloc))).image-left {
  border-bottom: 1px solid #8b8a93;
  border-right: none;
}

@media screen and (width <= 1024px) {
  .teaser.cmp-teaser-separator .cmp-teaser-bloc:not(:not(:has( ~ .cmp-teaser-bloc))).image-right, .teaser.cmp-teaser-separator .cmp-teaser-bloc:not(:not(:has( ~ .cmp-teaser-bloc))).image-left {
    border: none;
  }
}

.teaser.cmp-teaser-list ul {
  padding: 0;
}

.teaser.cmp-teaser-list ul li {
  background: url("../../../../../resources/images/icon-list.png") no-repeat;
  padding: 0 0 1.5rem 2.25rem;
  list-style: none;
}

.teaser.cmp-teaser-list .teaser-banner li {
  background: url("../../../../../resources/images/icon-list-white.png") no-repeat;
}

.teaser {
  padding: 3.125rem 0;
}

@media screen and (width <= 1024px) {
  .teaser {
    padding: 1rem 0;
  }
}

.teaser .cmp-teaser-title {
  color: #252339;
  padding-bottom: 1.875rem;
  padding-left: 1rem;
  font-family: Montserrat, Arial, sans-serif;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 2.75rem;
}

@media screen and (width <= 1024px) {
  .teaser .cmp-teaser-title {
    width: 80%;
    margin: auto;
  }
}

@media screen and (width <= 768px) {
  .teaser .cmp-teaser-title {
    width: 100%;
    padding-left: 0;
  }
}

.teaser .cmp-teaser-content {
  justify-content: center;
  align-items: stretch;
  display: flex;
}

@media screen and (width <= 1024px) {
  .teaser .cmp-teaser-content {
    flex-direction: column;
  }
}

.teaser .cmp-teaser-content .button {
  padding: .625rem 0;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 1rem;
  display: flex;
}

@media screen and (width <= 1024px) {
  .teaser .cmp-teaser-content .cmp-teaser-bloc {
    width: 80%;
    margin: auto;
  }
}

@media screen and (width <= 768px) {
  .teaser .cmp-teaser-content .cmp-teaser-bloc {
    width: 100%;
    padding: 1rem 0;
  }
}

.teaser .cmp-teaser-content .cmp-teaser-bloc .cmp-teaser-image, .teaser .cmp-teaser-content .cmp-teaser-bloc .cmp-teaser-image img {
  border-radius: .375rem;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc .cmp-teaser-label {
  color: #3e3d48;
  text-transform: uppercase;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc .cmp-teaser-bloctitle {
  color: #252339;
  padding-top: 1rem;
  font-family: Roboto, Arial, sans-serif;
  font-weight: 700;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc .cmp-teaser-subtitle {
  color: #3e3d48;
  font-weight: 700;
  line-height: 1.5rem;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc .cmp-teaser-blocdescription {
  line-height: 1.5rem;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc .cmp-teaser-blocdescription b {
  font-weight: 700;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc .cmp-teaser-arrow-link {
  width: fit-content;
  margin: 0;
  padding-top: .25rem;
  text-decoration: none;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc .cmp-teaser-arrow-link:after {
  content: " →";
}

.teaser .cmp-teaser-content .cmp-teaser-bloc .cmp-teaser-arrow-link:hover {
  text-decoration: underline;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc .cmp-teaser-button {
  width: 60%;
  margin: auto;
}

@media screen and (width <= 1024px) {
  .teaser .cmp-teaser-content .cmp-teaser-bloc .cmp-teaser-button {
    width: 100%;
  }
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.image-right, .teaser .cmp-teaser-content .cmp-teaser-bloc.image-left, .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-right, .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-left {
  display: flex;
}

@media screen and (width >= 1025px) {
  .teaser .cmp-teaser-content .cmp-teaser-bloc.image-right, .teaser .cmp-teaser-content .cmp-teaser-bloc.image-left, .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-right, .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-left {
    flex-direction: row;
  }
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.image-right .cmp-teaser-text, .teaser .cmp-teaser-content .cmp-teaser-bloc.image-left .cmp-teaser-text, .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-right .cmp-teaser-text, .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-left .cmp-teaser-text {
  flex-direction: column;
  display: flex;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.image-right .cmp-teaser-text .cmp-teaser-label, .teaser .cmp-teaser-content .cmp-teaser-bloc.image-left .cmp-teaser-text .cmp-teaser-label, .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-right .cmp-teaser-text .cmp-teaser-label, .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-left .cmp-teaser-text .cmp-teaser-label {
  padding-bottom: .5rem;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.image-right .cmp-teaser-text .cmp-teaser-bloctitle, .teaser .cmp-teaser-content .cmp-teaser-bloc.image-left .cmp-teaser-text .cmp-teaser-bloctitle, .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-right .cmp-teaser-text .cmp-teaser-bloctitle, .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-left .cmp-teaser-text .cmp-teaser-bloctitle {
  padding: 0 0 .5rem;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.image-right .cmp-teaser-text .cmp-teaser-subtitle, .teaser .cmp-teaser-content .cmp-teaser-bloc.image-left .cmp-teaser-text .cmp-teaser-subtitle, .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-right .cmp-teaser-text .cmp-teaser-subtitle, .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-left .cmp-teaser-text .cmp-teaser-subtitle {
  padding: 1rem 0;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.image-right .cmp-teaser-text .cmp-teaser-arrow-link, .teaser .cmp-teaser-content .cmp-teaser-bloc.image-left .cmp-teaser-text .cmp-teaser-arrow-link, .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-right .cmp-teaser-text .cmp-teaser-arrow-link, .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-left .cmp-teaser-text .cmp-teaser-arrow-link {
  padding-top: 2rem;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.image-right .cmp-teaser-text .cmp-teaser-button, .teaser .cmp-teaser-content .cmp-teaser-bloc.image-left .cmp-teaser-text .cmp-teaser-button, .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-right .cmp-teaser-text .cmp-teaser-button, .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-left .cmp-teaser-text .cmp-teaser-button {
  padding: 0;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.image-right, .teaser .cmp-teaser-content .cmp-teaser-bloc.image-left {
  flex-basis: 100%;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.image-right .cmp-teaser-image, .teaser .cmp-teaser-content .cmp-teaser-bloc.image-left .cmp-teaser-image {
  flex-basis: 50%;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.image-right .cmp-teaser-image img, .teaser .cmp-teaser-content .cmp-teaser-bloc.image-left .cmp-teaser-image img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 335px;
  min-height: 100%;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.image-right .cmp-teaser-text, .teaser .cmp-teaser-content .cmp-teaser-bloc.image-left .cmp-teaser-text {
  flex-basis: 50%;
}

@media screen and (width <= 1024px) {
  .teaser .cmp-teaser-content .cmp-teaser-bloc.image-right .cmp-teaser-text, .teaser .cmp-teaser-content .cmp-teaser-bloc.image-left .cmp-teaser-text {
    padding-top: 1.625rem;
  }
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.image-right .cmp-teaser-text .cmp-teaser-bloctitle, .teaser .cmp-teaser-content .cmp-teaser-bloc.image-left .cmp-teaser-text .cmp-teaser-bloctitle {
  font-size: 1.875rem;
  line-height: 2.75rem;
}

@media screen and (width <= 1024px) {
  .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-right, .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-left {
    flex-direction: row;
  }
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.picto-right .cmp-teaser-image, .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-left .cmp-teaser-image {
  background: #f8f8fd;
  width: 7.5rem;
  height: 7.5rem;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.picto-right .cmp-teaser-image img, .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-left .cmp-teaser-image img {
  -o-object-fit: contain;
  object-fit: contain;
  width: 1000%;
  height: 100%;
  padding: 1rem;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.picto-right .cmp-teaser-text, .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-left .cmp-teaser-text {
  flex: 1;
  justify-content: flex-start;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.picto-right .cmp-teaser-text .cmp-teaser-bloctitle, .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-left .cmp-teaser-text .cmp-teaser-bloctitle {
  color: #252339;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.picto-right .cmp-teaser-text .cmp-teaser-button, .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-left .cmp-teaser-text .cmp-teaser-button {
  width: fit-content;
}

.teaser .cmp-teaser-content {
  flex-wrap: wrap;
}

@media screen and (width <= 1024px) {
  .teaser .cmp-teaser-content .cmp-teaser-bloc.image-left {
    flex-direction: column;
  }
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.image-left .cmp-teaser-text {
  padding-left: 2.625rem;
}

@media screen and (width <= 1024px) {
  .teaser .cmp-teaser-content .cmp-teaser-bloc.image-left .cmp-teaser-text {
    padding-left: 0;
  }
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.picto-left .cmp-teaser-text {
  padding-left: 1.625rem;
}
/*# sourceMappingURL=_teaser_left.css.map */
