@import './_teaser.scss';

.teaser {
  .cmp-teaser {
    &-content {
      flex-wrap: wrap;

      .cmp-teaser {
        &-bloc {
          // common
          &.image-right,
          &.image-left,
          &.picto-right,
          &.picto-left {
            display: flex;

            @media screen and (min-width: $tablet + 1px) {
              flex-direction: row;
            }

            .cmp-teaser {
              &-text {
                display: flex;
                flex-direction: column;

                .cmp-teaser {
                  &-label {
                    padding-bottom: .5rem;
                  }

                  &-bloctitle {
                    padding: 0 0 0.5rem;
                  }

                  &-subtitle {
                    padding: 1rem 0;
                  }

                  &-arrow-link {
                    padding-top: 2rem;
                  }

                  &-button {
                    padding: 0;
                  }
                }
              }
            }
          }

          // image
          &.image-right,
          &.image-left {
            flex-basis: 100%;

            .cmp-teaser {
              &-image {
                flex-basis: 50%;
                
                img {
                  height: 335px;
                  min-height: 100%;
                  object-fit: cover;
                  width: 100%;
                }
              }

              &-text {
                flex-basis: 50%;

                @media screen and (max-width: $tablet) {
                  padding-top: 1.625rem;
                }
                
                .cmp-teaser {
                  &-bloctitle {
                    font-size: 1.875rem;
                    line-height: 2.75rem;
                  }
                }
              }
            }
          }

          // picto
          &.picto-right,
          &.picto-left {
            @media screen and (max-width: $tablet) {
              flex-direction: row;
            }

            .cmp-teaser {
              &-image {
                background: #F8F8FD;
                height: 7.5rem;
                width: 7.5rem;

                img {
                  height: 100%;
                  object-fit: contain;
                  padding: 1rem;
                  width: 1000%;
                }
              }

              &-text {
                flex: 1;
                justify-content: flex-start;

                .cmp-teaser {
                  &-bloctitle {
                    color: $titleColor;
                    font-size: 1.125rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 1.5rem;
                  }

                  &-button {
                    width: fit-content;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}